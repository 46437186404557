import React, { useState } from 'react'
import {
  Form,
  Input,
  InputNumber,
  Select,
  Switch,
} from 'antd'
import { quanityValidator } from 'modules/utils/validators'

import './index.styl'

const { Item } = Form
const { TextArea } = Input

const PassForm = ({ name, onRemove, form, currentPageDetails, categoryData }) => {
  const [availablePass, setAvailablePass] = useState(form.getFieldValue('showAvailableTickets') || false)

  const availablePassHandler = () => {
    setAvailablePass(!availablePass)
    // form.setFieldsValue({
    //  showAvailableTickets: !availableTicket,
    // })
  }

  return (
    <div className='passWrapper'>
      <div className='passTopField'>
        <Item
          name={[name, 'name']} label='Pass Category' rules={[
            { required: true, message: 'Pass Category is required!' },
            { max: 100, message: 'Pass Category cannot exceed 100 characters!' },
          ]}
          normalize={v => v.trimStart()}
        >
          <Input className='categoryPass' placeholder='Pass' />
        </Item>
        <div className='passPriceQuantity'>
          <Item
            name={[name, 'price']} label='Pass Price' rules={[
              { required: true, message: 'Pass Price is required!' },
              {
                validator: (_, value) => {
                  if (value === 0 || value >= 1) {
                    return Promise.resolve()
                  }
                  return Promise.reject(new Error('Price must be 0 or greater than or equal to 1'))
                },
              },
            ]}
          >
            <InputNumber disabled={categoryData?.price >= 0 && currentPageDetails} placeholder='0' className='price' min={0} />
          </Item>
          <Item
            name={[name, 'quantity']} label='Pass Quantity' rules={[
              { required: true, message: 'Pass Quantity is required!' },
              { type: 'number', max: 100000, message: 'Quantity cannot exceed 100 000' },
              quanityValidator,

            ]}
          >
            <InputNumber
              className='quantity'
              placeholder='0'
              min={1}
              step={1}
            />
          </Item>
        </div>
        <Item name={[name, 'duration']} label='Duration' rules={[{ required: true, message: 'Select duration!' }]}>
          <Select disabled={categoryData?.duration && currentPageDetails} className='duration'>
            <Select.Option value='Daily'>Daily</Select.Option>
            <Select.Option value='Weekly'>Weekly</Select.Option>
            <Select.Option value='Monthly'>Monthly</Select.Option>
            <Select.Option value='Yearly'>Yearly</Select.Option>
          </Select>
        </Item>
      </div>
      <div className='radioItemField showAvailablePass'>
        <Item
          name={[name, 'showAvailableTickets']}
          valuePropName='checked'
          className='item'
          initialValue={availablePass}
        >
          <Switch onChange={availablePassHandler} defaultChecked={availablePass} />
        </Item>
        <div className='text'>Show available passes</div>
      </div>
      <Item
        rules={[
          { required: false },
          { max: 500, message: 'Additional Info cannot exceed 500 characters!' },
        ]}
        name={[name, 'description']}
        label='Additional Info'
        normalize={v => v.trimStart()}
      >
        <TextArea autoSize={{ maxRows: 50 }} className='additionalInfo' />
      </Item>
      <div className='btnWrapper'>
        <div className='removeBtn' onClick={() => onRemove(name)}>
          Delete Pass
        </div>
      </div>
    </div>
  )
}
export default PassForm
