import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
  getPageDetails,
  checkMembershipMember,
} from 'modules/memberships/memberships-actions'
import { getEventIDByURL } from 'modules/events/events-actions'
import { MemberLyfeLayout } from 'components'
import './index.styl'
import Header from './Header'
import { useHistory } from 'react-router'

const MemberPage = () => {
  const { id, customURL } = useParams()
  const { user } = useSelector(state => state.session)
  const [vanityId, setVanityId] = useState('')
  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    if (!user) {
      history.push(`/auth/sign-in?redirectTo=${location.pathname}`)
    } else {
      if (customURL) {
        dispatch(getEventIDByURL(customURL)).then(res => {
          setVanityId(res.data.data)
        })
      } else {
        dispatch(getPageDetails(id))
        dispatch(checkMembershipMember(id))
      }
    }
  }, [])

  return (
    <MemberLyfeLayout className='wrapperHome'>
      {user && <Header vanityId={vanityId} />}
    </MemberLyfeLayout>
  )
}

export default MemberPage
