import React, { useEffect, useRef, useState } from 'react'
import { ReactComponent as ClockIcon } from 'images/timeGrey.svg'

import ClearImage from 'images/date-cross.svg'

import './index.styl'
import { Input, Form, Button, notification } from 'antd'

const { Item } = Form

const TimePicker = ({ form, time, nameText, namePrefix, label, fieldName, msTime = false }) => {
  const [showPicker, setShowPicker] = useState(false)
  const [selectedTime, setSelectedTime] = useState({
    start: {
      hour: '',
      minute: '',
      ampm: '',
    },
    end: {
      hour: '',
      minute: '',
      ampm: '',
    },
  })

  const [activeField, setActiveField] = useState('hour')
  const [activeTime, setActiveTime] = useState('start')
  const [inputValue, setInputValue] = useState('')
  const timePickerRef = useRef(null)
  useEffect(() => {
    if (time) {
      setSelectedTime(time)
    }
  }, [time])

  const handleClickOutside = event => {
    if (
      timePickerRef.current &&
      !timePickerRef.current.contains(event.target) &&
      !event.target.classList.contains('clear-button') // Exclude clicks on "Clear" buttons
    ) {
      setShowPicker(false)
      // setSelectedTime(() => ({
      //  end: {
      //    hour: '',
      //    minute: '',
      //    ampm: '',
      //  },
      //  start: {
      //    hour: '',
      //    minute: '',
      //    ampm: '',
      //  },
      // }))
    }
  }

  const togglePicker = () => {
    setShowPicker(!showPicker)
  }

  const formatTime = (hour, minute, ampm) => {
    if (minute === '00') {
      return `${hour}${ampm.toUpperCase()}`
    } else {
      return `${hour}:${String(minute).padStart(2, '0')}${ampm.toUpperCase()}`
    }
  }

  const startTime = selectedTime.start.hour !== '' ? formatTime(selectedTime.start.hour, selectedTime.start.minute, selectedTime.start.ampm) : ''
  const endTime = selectedTime.end.hour !== '' ? formatTime(selectedTime.end.hour, selectedTime.end.minute, selectedTime.end.ampm) : ''

  const timeRange = `${startTime}${startTime && endTime ? ' - ' : ''}${endTime}`
  const convertTo24Hour = (hour, ampm) => {
    let hour24 = parseInt(hour, 10)
    if (ampm === 'pm' && hour24 < 12) {
      hour24 += 12
    }
    if (ampm === 'am' && hour24 === 12) {
      hour24 = 0
    }
    return hour24
  }
  const startHour24 = convertTo24Hour(selectedTime.start.hour, selectedTime.start.ampm)
  const endHour24 = convertTo24Hour(selectedTime.end.hour, selectedTime.end.ampm)
  const startMinute = parseInt(selectedTime.start.minute || '0', 10)
  const endMinute = parseInt(selectedTime.end.minute || '0', 10)

  const isTimeRangeValid = endHour24 > startHour24 || (endHour24 === startHour24 && endMinute > startMinute)

  const isStartTimeValid = selectedTime.start.hour !== '' && selectedTime.start.minute !== '' && selectedTime.start.ampm !== ''
  const isEndTimeValid = selectedTime.end.hour !== '' && selectedTime.end.minute !== '' && selectedTime.end.ampm !== ''

  const isSetButtonDisabled = !isStartTimeValid || !isEndTimeValid || !isTimeRangeValid
  const onOk = () => {
    if (isSetButtonDisabled) {
      notification.warning({
        message: 'Invalid Time Range',
        description: 'End time must be later than start time.',
      })
      return
    }
    if (!isStartTimeValid || !isEndTimeValid) {
      // Perform any additional actions or display an error message if needed
      return
    }
    // Update the form field value with the selected time
    const newTimeValue = {
      start: {
        hour: selectedTime.start.hour,
        minute: selectedTime.start.minute,
        ampm: selectedTime.start.ampm,
      },
      end: {
        hour: selectedTime.end.hour,
        minute: selectedTime.end.minute,
        ampm: selectedTime.end.ampm,
      },
    }

    setInputValue(
      `${nameText === 'time' ? `${selectedTime.start.hour !== '' ? `${selectedTime.start.hour}:${String(selectedTime.start.minute).padStart(2, '0')} ${selectedTime.start.ampm}` : ''} ${inputValue || selectedTime.start.ampm ? '-' : ''} ${selectedTime.end.hour !== '' ? `${selectedTime.end.hour}:${String(selectedTime.end.minute).padStart(2, '0')} ${selectedTime.end.ampm}` : ''}` : timeRange}`,
    )
    // Set the form field value
    // onChange(newTimeValue)
    const currentField = form.getFieldValue(fieldName)
    if (msTime) {
      currentField[namePrefix].time = newTimeValue
    }

    form.setFieldsValue({
      [fieldName]: currentField,
    })
    form.setFieldsValue({
      [nameText]: newTimeValue,
    })

    // Update the state using the functional form of setState

    setShowPicker(false)
  }

  const onCancel = () => {
    setShowPicker(false)
    setSelectedTime(() => ({
      end: {
        hour: '',
        minute: '',
        ampm: '',
      },
      start: {
        hour: '',
        minute: '',
        ampm: '',
      },
    }))
    setInputValue('')
  }

  const handleFieldClick = field => {
    setActiveField(field)
  }

  const handleTimeTypeClick = timeType => {
    setActiveTime(timeType)
  }

  const handleChange = (timeType, field, value) => {
    setSelectedTime(prev => ({
      ...prev,
      [timeType]: {
        ...prev[timeType],
        [field]: value,
      },
    }))
  }
  const renderTimeSelector = (timeType, field) => {
    const values = field === 'hour' ? Array.from({ length: 12 }, (_, i) => i + 1) : [15, 30, 45, 0]
    const activeValue = selectedTime[timeType][field]
    const activeIndex = values.indexOf(activeValue)
    const totalValues = values.length

    const cyclicValues = Array.from({ length: 4 }, (_, i) => (activeIndex - 1 + i + totalValues) % totalValues)
    const handleWheelChange = (event, timeType, field) => {
      event.stopPropagation()
      event.preventDefault()
      const delta = event.deltaY
      const direction = delta > 0 ? 1 : -1 // 1 for down, -1 for up
      const values = field === 'hour' ? Array.from({ length: 12 }, (_, i) => i + 1) : [15, 30, 45, 0]
      const activeValue = selectedTime[timeType][field]
      const activeIndex = values.indexOf(activeValue)
      const totalValues = values.length

      const newIndex = (activeIndex + direction + totalValues) % totalValues
      const newValue = values[newIndex]

      handleChange(timeType, field, newValue)
      handleFieldClick(field)
    }

    return (
      <div
        className='time-selector'
      >
        {cyclicValues.map(index => {
          const value = values[index]
          const isSelected = selectedTime[timeType][field] === value
          const isNeighbor = Math.abs(activeIndex - index) > 0
          const color = isNeighbor ? '#8F93AE' : '#1A1D24'
          return (
            <div
              key={index}
              className={`${isSelected ? 'selected' : ''} ${isSelected && activeField === field ? 'active' : ''}`}
              onWheel={e => {
                handleWheelChange(e, timeType, field)
              }}
              onTouchMove={e => {
                handleWheelChange(e, timeType, field)
              }}
              onClick={() => {
                handleChange(timeType, field, value)
                handleFieldClick(field)
              }}
              style={{ color }}
            >
              {field === 'hour' ? String(value).padStart(2, '') : String(value).padStart(2, '0')}
            </div>
          )
        })}
      </div>
    )
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  const renderAmPmSelector = timeType => {
    const ampmValues = ['pm', 'am']
    const activeValue = selectedTime[timeType].ampm
    const activeIndex = ampmValues.indexOf(activeValue)
    const totalValues = ampmValues.length

    const cyclicValues = Array.from({ length: 2 }, (_, i) => (activeIndex - i + totalValues) % totalValues)

    return (
      <div className={`ampm-selector ${activeField === 'ampm' ? 'active' : ''}`}>
        {cyclicValues.map(index => {
          const value = ampmValues[index]
          const isSelected = selectedTime[timeType].ampm === value
          const isNeighbor = Math.abs(activeIndex - index) > 0
          const color = isNeighbor ? '#8F93AE' : '#1A1D24'
          return (
            <div
              key={index}
              className={`${isSelected ? 'selected' : ''} ${isSelected && activeField === 'ampm' ? 'active' : ''}`}
              style={{ color }}
              onClick={() => {
                handleChange(timeType, 'ampm', value)
                handleFieldClick('ampm')
              }}
            >
              {value}
            </div>
          )
        })}
      </div>
    )
  }
  const handleClearStartTime = () => {
    setSelectedTime(prev => ({
      ...prev,
      start: {
        hour: '',
        minute: '',
        ampm: '',
      },
    }))
  }
  const handleClearEndTime = () => {
    setSelectedTime(prev => ({
      ...prev,
      end: {
        hour: '',
        minute: '',
        ampm: '',
      },
    }))
  }
  const customHeader = () => {
    return (
      <div className='headerTimePicker'>
        <div className='wrapperBlock'>
          <div className='startDateInfoWrapper'>
            <div
              onClick={() => handleTimeTypeClick('start')}
              className={`startDateWrapper ${activeTime === 'start' ? 'active' : ''}`}
            >
              <div>
                <div
                  className='titleDate'
                >Start Time
                </div>
                {selectedTime.start?.hour &&
                  <div>
                    {`${selectedTime.start?.hour}:${selectedTime.start?.minute !== '' ? String(selectedTime.start?.minute).padStart(2, '0') : ''} ${selectedTime.start?.ampm}`}
                  </div>}
              </div>
            </div>
            {activeTime === 'start' && selectedTime.start?.hour && String(selectedTime.start?.minute).padStart(2, '0') && selectedTime.start?.ampm && <img className='clear-button' onClick={handleClearStartTime} src={ClearImage} alt='' />}
          </div>
          <div className='endDateInfoWrapper'>
            <div
              onClick={() => handleTimeTypeClick('end')}
              className={`endDateWrapper ${activeTime === 'end' ? 'active' : ''}`}
            >
              <div>
                <div
                  className='titleDate'
                >End Time
                </div>
                {selectedTime.end?.hour &&
                  `${selectedTime.end?.hour}:${selectedTime.end?.minute !== '' ? String(selectedTime.end?.minute).padStart(2, '0') : '00'} ${selectedTime.end?.ampm}`}
              </div>
            </div>
            {activeTime === 'end' && (selectedTime.end.hour && String(selectedTime.end?.minute).padStart(2, '0') && selectedTime.end.ampm) && <img className='clear-button' onClick={handleClearEndTime} src={ClearImage} alt='' />}
          </div>
        </div>
      </div>
    )
  }
  useEffect(() => {
    const handleScroll = event => {
      event.preventDefault()
    }

    const handleTouchMove = event => {
      event.preventDefault()
    }

    const timePickerElement = timePickerRef.current

    timePickerElement.addEventListener('wheel', handleScroll, { passive: false })
    timePickerElement.addEventListener('touchmove', handleTouchMove, { passive: false })

    return () => {
      timePickerElement.removeEventListener('wheel', handleScroll)
      timePickerElement.removeEventListener('touchmove', handleTouchMove)
    }
  }, [])

  return (
    <div
      ref={timePickerRef}
    >
      <Item
        rules={[
          { required: true, message: 'Time is required!' },
          {
            validator: (_, value) => {
              return new Promise(resolve => {
                if (!value || !value.start || !value.end) {
                  // Display notification for missing start or end time
                  notification.warning({
                    message: 'Missing Time Selection',
                    description: 'Please select both start and end times.',
                  })
                } else if (!isStartTimeValid || !isEndTimeValid) {
                  // Display notification for invalid time range
                  notification.warning({
                    message: 'Invalid Time Range',
                    description: 'Please select valid start and end times.',
                  })
                } else {
                  resolve()
                }
              })
            },
          },
        ]}
        label={label}
        className={!msTime ? 'defaultInputLabel timeInput' : ' defaultInputLabel upcomingEventsInput'}
        name={!msTime ? 'time' : [namePrefix, nameText]}
      >
        <div name={!msTime ? 'time' : nameText}>
          <Input
            value={!msTime ? inputValue || `${selectedTime.start.hour !== '' ? `${selectedTime.start.hour}:${String(selectedTime.start.minute).padStart(2, '0')} ${selectedTime.start.ampm}` : ''} ${inputValue || selectedTime.start.ampm ? '-' : ''} ${selectedTime.end.hour !== '' ? `${selectedTime.end.hour}:${String(selectedTime.end.minute).padStart(2, '0')} ${selectedTime.end.ampm}` : ''}` : inputValue || timeRange}
            onClick={togglePicker}
            suffix={
              <ClockIcon />
            }
          />
          {showPicker && (
            <>
              <div className='timePickerWrapper'>
                {customHeader()}
                <div className='timePickerPadding'>
                  <div className='titleWithPickerWrapper'>
                    <div className='titleTime'>Start Time</div>
                    <div className={`${'start'}TimeWrapper`} onClick={() => handleTimeTypeClick('start')}>
                      {renderTimeSelector('start', 'hour')}
                      <div className='separator'>:</div>
                      {renderTimeSelector('start', 'minute')}
                      {renderAmPmSelector('start')}
                    </div>
                  </div>

                  <div className='titleWithPickerWrapper'>
                    <div className='titleTime'>End Time</div>
                    <div className={`${'end'}TimeWrapper`} onClick={() => handleTimeTypeClick('end')}>
                      {renderTimeSelector('end', 'hour')}
                      <div className='separator'>:</div>
                      {renderTimeSelector('end', 'minute')}
                      {renderAmPmSelector('end')}
                    </div>
                  </div>
                </div>
                <div className='timeFooterBtn'>
                  <Button onClick={onCancel}>Clear</Button>
                  <Button disabled={isSetButtonDisabled} className='setBtn' onClick={onOk}>Set</Button>
                </div>
              </div>
            </>
          )}
        </div>
      </Item>
    </div>
  )
}

export default TimePicker
