import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Tooltip, Skeleton } from 'antd'
import { ModalAcceptInvite, ModalDetailTicket, CustomButton } from 'components'

import { getMyWalletTickets } from 'modules/events/events-actions'
import Card from './Card'
import NoEventsInformation from 'images/NoEventsInformation.svg'
import { isOnDemand } from 'utils'
import { useLocation } from 'react-router'
import './index.styl'
import SpecialCard from './SpecialCard'

const limit = 20

const Body = ({ valueSearch, loading, setLoading, sortType, setTotal, setCurrent, setSkip, skip, current, total, setLoadingPagination, loadingPagination }) => {
  const [inviteToAccept, setInviteToAccept] = useState()
  const [selectedTicket, setSelectedTicket] = useState()

  const dispatch = useDispatch()
  const [showModal, setShowModal] = useState(false)
  const [currentAcceptedTickets, setCurrentAcceptedTickets] = useState([])
  const { location } = useLocation()
  const { accepted, pending } = useSelector(state => state.events.myWallets)
  const { user } = useSelector(state => state.session)
  const { walletPendingDataGuest } = useSelector(state => state.events)
  const handleFetch = obj => {
    dispatch(getMyWalletTickets(obj))
      .then(({ total, current }) => {
        setTotal(total)
        setCurrent(current)
        setSkip(obj.skip)
        setLoading(false)
        setLoadingPagination(false)
      })
      .catch(() => setLoading(false))
  }

  const handleFetchMore = () => {
    setLoadingPagination(true)
    handleFetch({ type: sortType, limit, skip: skip + limit, search: valueSearch })
  }

  useEffect(() => {
    setTotal(0)
    setCurrent(0)
    setSkip(0)
  }, [sortType])

  // useEffect(() => {
  //  handleFetch({ limit, skip, search: valueSearch })
  // }, [])

  useEffect(() => {
    if (accepted?.length) {
      setCurrentAcceptedTickets(accepted
        .map(event => isOnDemand(event?.eventEnd) ? ({ ...event, onDemand: true }) : event),
      )
    }
  }, [JSON.stringify(accepted)])

  const pendingTicket = (
    <div className='wrapperInvites'>
      <div className='title'>Invites Sent To Me:</div>
      <div className='wrapperCards'>
        {pending?.map((i, ind) =>
          <Card key={ind} item={i} tag='Invite' setShowModal={setShowModal} onClick={setSelectedTicket} onSelectInvite={setInviteToAccept} />,
        )}
      </div>
    </div>
  )

  const pendingTicketGuest = (
    <div className='wrapperInvites'>
      <div className='title'>Invitations and Tickets Sent to you:</div>
      <div className='wrapperCards'>
        {walletPendingDataGuest?.map((i, ind) =>
          <Card key={ind} pending={pending} item={i} tag='Invite' guest setShowModal={setShowModal} onClick={setSelectedTicket} onSelectInvite={setInviteToAccept} />,
        )}
      </div>
    </div>
  )
  return (
    <div className='wrapperBodyMyWallet'>
      <Skeleton loading={loading} active paragraph={{ rows: 15 }}>
        {currentAcceptedTickets?.length || pending?.length || walletPendingDataGuest?.length ? (
          <>
            {currentAcceptedTickets?.length > 0 && (
              <>
                <div className='wrapperCards'>
                  {currentAcceptedTickets.map((i, ind) => (
                    <>
                      {i?.ticketType === 'RAFFLE' || i?.ticketType === 'ITEM'
                        ? <SpecialCard pathname={location} key={ind} item={i} tag='Ticket' setShowModal={setShowModal} onClick={setSelectedTicket} />
                        : <Card key={ind} item={i} tag='Ticket' setShowModal={setShowModal} onClick={setSelectedTicket} />}
                    </>
                  ))}
                </div>
                <div className='fetchMoreContainerWallet'>
                  {current < total && (
                    <CustomButton
                      disabled={current >= total || loadingPagination}
                      loading={loadingPagination}
                      onClick={handleFetchMore}
                      // text={`Show More ${restLength > limit ? limit : restLength}`}
                      text='Show More'
                      type='dark'
                    />
                  )}
                </div>
              </>
            )}
            {pending?.length > 0 && pendingTicket}
            {!user && walletPendingDataGuest?.length > 0 && pendingTicketGuest}
          </>
        ) : (
          <div className='wrapperBodyEmpty'>
            <Tooltip title='tooltip example'>
              <img src={NoEventsInformation} alt='Information Logo' />
            </Tooltip>
            <div className='informationDescription'>No tickets available yet</div>
          </div>
        )}
      </Skeleton>
      {inviteToAccept && <ModalAcceptInvite inviteData={inviteToAccept} onCloseModal={() => setInviteToAccept()} />}
      {showModal && <ModalDetailTicket ticketData={selectedTicket} onCloseModal={() => setShowModal(false)} onSelectInvite={setInviteToAccept} />}
    </div>
  )
}

export default Body
