import React from 'react'
import c from 'classnames'
import { Skeleton } from 'antd'
import './index.styl'

const StatisticsBox = ({ statistics, currencySymbol, isML = false }) => {
  return !statistics ? <Skeleton active title={false} /> : (
    <div className={c('wrapperBoxes', `group-${Object.keys(statistics).length}`)}>
      {statistics?.price &&
        <div className='wrapperBox'>
          {currencySymbol}{statistics?.price}
          <div className={c('box', 'price')}>Tickets Price</div>
        </div>}
      {statistics?.soldQuantity &&
        <div className='wrapperBox'>
          {statistics?.soldQuantity}
          <div className={c('box', 'soldQuantity')}>Tickets Sold</div>
        </div>}
      {statistics?.onlineSales &&
        <div className='wrapperBox'>
          {currencySymbol}{statistics?.onlineSales}
          <div className={c('box', 'total')}>Online Sales</div>
        </div>}
      {statistics?.salesRevenue &&
        <div className='wrapperBox'>
          {currencySymbol}{statistics?.salesRevenue}
          <div className={c('box', 'total')}>Promoter's Take</div>
        </div>}
      {statistics?.cashSalesRevenue &&
        <div className='wrapperBox'>
          {currencySymbol}{statistics?.cashSalesRevenue}
          <div className={c('box', 'soldQuantity')}>Cash/Check Sales</div>
        </div>}
      {'managersTake' in statistics &&
        <div className='wrapperBox'>
          {currencySymbol}{statistics?.managersTake}
          <div className={c('box', 'salesRevenue')}>Manager's Take</div>
        </div>}
      {'activePasses' in statistics &&
        <div className='wrapperBox'>
          {statistics?.activePasses}
          <div className={c('box', 'refundAmount')}>Active Passes</div>
        </div>}
      {'numberOfMembers' in statistics &&
        <div className='wrapperBox'>
          {statistics?.numberOfMembers}
          <div className={c('box', 'onHand')}>Number of Members</div>
        </div>}
      {'membershipsGifted' in statistics &&
        <div className='wrapperBox'>
          {statistics?.membershipsGifted}
          <div className={c('box', 'donations')}>Memberships Gifted</div>
        </div>}
      {'seasonPassesScanned' in statistics && 'sessionPassesScanned' in statistics &&
        <div className='wrapperBox'>
          {+statistics?.seasonPassesScanned + +statistics?.sessionPassesScanned}
          <div className={c('box', 'ticketsMade')}>Memberships Scanned</div>
        </div>}
      {'sessionsDistributed' in statistics &&
        <div className='wrapperBox'>
          {statistics?.sessionsDistributed}
          <div className={c('box', 'total')}>Sessions Distributed</div>
        </div>}
      {'itemsSold' in statistics &&
        <div className='wrapperBox'>
          {statistics?.itemsSold}
          <div className={c('box', 'soldQuantity')}>Items Sold</div>
        </div>}
      {statistics?.ticketsMade &&
        <div className='wrapperBox'>
          {statistics?.ticketsMade}<div className={c('box', 'ticketsMade')}>Tickets Made</div>
        </div>}
      {statistics && !isML &&
        <div className='wrapperBox'>
          {statistics?.ticketsDistributed ? statistics?.ticketsDistributed : '0'} <div className={c('box', 'onHand')}>Tickets Distributed</div>
        </div>}
      {!!statistics?.refundAmount &&
        <div className='wrapperBox'>
          {currencySymbol}{statistics?.refundAmount}
          <div className={c('box', isML ? 'salesRevenue' : 'refundAmount')}>Refunded</div>
        </div>}
      {statistics && !isML &&
        <div className='wrapperBox'>
          {statistics?.ticketsGifted ? statistics?.ticketsGifted : '0'}<div className={c('box', 'ticketsGifted')}>Tickets Gifted</div>
        </div>}
      {!!statistics?.scanned &&
        <div className='wrapperBox'>
          {statistics?.scanned.scanned + '/' + statistics?.scanned.total}<div className={c('box', 'ticketsGifted')}>Tickets Scanned</div>
        </div>}
      {statistics?.donationAmount &&
        <div className='wrapperBox'>
          {currencySymbol}{statistics?.donationAmount} <div className={c('box', 'donations')}>Donations Received</div>
        </div>}
      {statistics?.subpromotedRevenue &&
        <div className='wrapperBox'>
          {currencySymbol}{statistics?.subpromotedRevenue}
          <div className={c('box', 'subpromotion')}>Subpromotion Sales</div>
        </div>}
    </div>
  )
}

export default StatisticsBox
