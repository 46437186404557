import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation, useParams, useHistory } from 'react-router-dom'
import {
  getEvents,
  getEventDetails,
  getEventDetailsV2,
  getEventDetailsSummaryV2,
  clearCurrentEvent,
  resetEventSummary,
  eventDetailsV2,
} from 'modules/events/events-actions'
import { StatisticsBox, CreateEventV2, ModalDetailTicket } from 'components'
import { Spin, notification, Button } from 'antd'
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import moment from 'moment'
import Statistics from '../Statistics/index'
import Categories from '../Categories/index'
import Distribution from '../Distribution/index'
import AssignedSeatings from '../AssignedSeatings'
import AssignedTables from '../Tables'
import RandomDraw from '../RandomDraw'
import ScanTickets from '../ScanTickets/index'
import PurchaseDetails from '../PurchaseDetails/index'
import PaymentBalances from '../PaymentBalances/index'
import FinancialReports from '../FinancialReports/index'
import BannerMyBoxOffice from 'components/BannerMyBoxOffice'
import BundlesDistribution from '../BundlesDistribution'
import { getCurrencySymbolByCode, getEventType, getMyBackOfficeURL, getMyBoxOfficeURL, isAdmin, isVenue } from 'utils'
import { ModalEmbedCode } from 'pages/MyBoxOffice/MBOComponents'
import { camelCase, isEmpty } from 'lodash'
// import QRCode from 'qrcode.react'
import { QRCode } from 'react-qrcode-logo'

import QRCodeLogoIcon from 'images/QRCodeLogo.svg'
import locationIcon from 'images/Location.svg'
import placeIcon from 'images/Place.svg'
import calendarIcon from 'images/Calendar.svg'
import timeIcon from 'images/Time.svg'
import copyLinkImg from 'images/copyLinkImg.svg'
import randomDrawIcon from 'images/randomDraw.svg'
import distributionIcon from 'images/distribution.svg'
import bundlesIcon from 'images/bundlesBoxOffice.svg'
import categoriesIcon from 'images/Category.svg'
import scannerIcon from 'images/Scanner.svg'
import financeReport from 'images/financeReport.svg'
import purchaseDetails from 'images/purchaseDetails.svg'
import zoomIcon from 'images/zoomIcon.svg'
import EditIcon from 'images/Edit.svg'
import arrow from 'images/simpleArrow.svg'
import copyIcon from 'images/copyIcon.png'
import streamIcon from 'images/streamIcon.png'
import tablesIcon from 'images/tablesIcon.svg'
import seatsIcon from 'images/seatsIcon.svg'
import embedCodeIcon from 'images/embedCode.svg'
import previewTicketIcon from 'images/previewTicket.svg'
import viewQRCode from 'images/viewQRCode.svg'
import closeIconSvg from 'images/Crossmark.svg'
import c from 'classnames'
import './index.styl'
import { MakeTicketComponents } from '../../../../components'

const HiddenField = ({ label, value }) => {
  const [visible, setVisible] = useState(false)
  return (
    <div className='hidden_field'>
      <div className={c('field_text', { select: !visible })}>
        {visible ? value : label}
      </div>
      <div onClick={() => setVisible(!visible)} className='toggle'>
        {visible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
      </div>
    </div>
  )
}

const EventDetails = () => {
  const { pathname, state = {} } = useLocation()
  const { push } = useHistory()
  const { eventID } = useParams()
  const containerRef = useRef(null)
  const dispatch = useDispatch()
  const [currentEvent, setCurrentEvent] = useState({})
  const [isResponsive, setIsResponsive] = useState(false)
  const {
    currentEventDetails, currentEventDetailsSummary, events,
    currentEventDetailsV2,
  } = useSelector(state => state.events)
  const [modalEmbedCode, setModalEmbedCode] = useState(null)
  const [isChange, setIsChange] = useState(false)
  const [selectedTicket, setSelectedTicket] = useState()
  const [tableData, setTableData] = useState([])
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const { user } = useSelector(state => state.session)
  const ticketOwnerId = currentEventDetails.userID
  const ticketVenueUserID = currentEventDetails?.venueUserID
  const isOwner = user?._id === ticketOwnerId || user?._id === ticketVenueUserID || isAdmin(user?.role)
  useEffect(() => {
    setIsResponsive(window.innerWidth < 1200)
  }, [window.innerWidth])
  const handleModalPreview = () => {
    const data = currentEventDetails.tickets?.map(i => {
      const ticketData = {
        isInvite: false,
        category: i?.ticketType,
        ticketType: i?.ticketType,
        quantity: i?.quantity,
        originalPrice: i?.price,
        total: i?.ticketCount?.total,
        directSell: i?.ticketCount?.sell,
        description: currentEventDetails.description,
        cashSalesRevenue: i?.ticketCount?.cashSales,
        gift: i?.ticketCount?.gift,
        sold: i?.ticketCount?.buy,
        subPromote: i?.ticketCount?.promote,
        available: i?.ticketCount?.available,
        price: i?.price || 0,
        id: i?._id,
        eventID,
        userID: currentEventDetails.userID,
        eventName: currentEventDetails.eventName,
        startDate: currentEventDetails.startDate,
        endDate: currentEventDetails.endDate,
        ticketColor: i.bgColor,
        isVirtualTicket: currentEventDetails.isVirtualTicket,
        isStreamingEvent: i.isStreaming,
        image: currentEventDetails.imageUrl?.thumbnail1,
        currency: currentEventDetails.currency,
        eventType: currentEventDetails.eventType,
        presentedBy: currentEventDetails.presentedBy,
        imageUrl: currentEventDetails.imageUrl,
        rainDate: getUtcOffsetDate(currentEventDetails.rainDate, 'MMMM DD, YYYY', currentEventDetails.timeZone),
      }
      if (!currentEventDetails.isVirtualTicket) {
        ticketData.venue = currentEventDetails?.venue
        ticketData.address = currentEventDetails?.address
      }
      return ticketData
    })
    if (currentEventDetails.isDonationEnable && currentEventDetails?.userID === user._id) {
      const objDonationRow = {
        id: currentEventDetails.donations?._id,
        category: 'Donations',
        total: currentEventDetails.donations?.quantity || 0,
        price: currentEventDetails.donations ? currentEventDetails.donations.total : 0,
        isDonation: true,
      }
      data.push(objDonationRow)
    }
    setSelectedTicket(...data)
  }
  const params = {
    type: 'tl',
  }
  useEffect(() => {
    dispatch(eventDetailsV2({}))
    dispatch(getEvents(params))
    dispatch(getEventDetails(eventID))
    dispatch(getEventDetailsV2(eventID))
    dispatch(clearCurrentEvent())
  }, [])
  useEffect(() => {
    if (isChange) {
      dispatch(getEvents(params))
    }
  }, [isChange])
  useEffect(() => {
    if (currentEventDetails && typeof currentEventDetails?.isPromoted === 'boolean') dispatch(getEventDetailsSummaryV2(eventID, currentEventDetails?.isPromoted))
  }, [currentEventDetails?.isPromoted])

  useEffect(() => {
    const data = events.filter(i => i._id === eventID)[0]
    setCurrentEvent(data)
  }, [events.length])

  const handleModal = () => setModalEmbedCode(`?e=${eventID}&u=${currentEventDetails?.userID}`)
  const handleCopy = () => {
    notification.success({ message: 'Code copied to clipboard success' })
    setModalEmbedCode(null)
  }

  const openPopup = () => {
    setIsPopupOpen(true)
  }

  const closePopup = () => {
    setIsPopupOpen(false)
  }

  const handleOverlayClick = e => {
    if (e.target === e.currentTarget) {
      closePopup()
    }
  }

  const eventType = getEventType(currentEventDetails)
  const handleScroll = () => containerRef.current.scrollIntoView()
  const getUrl = _state => {
    if (_state?.mainLink?.includes('back')) {
      return getMyBackOfficeURL({ ..._state, current: _state.page, statuses: _state.filter })
    }
    if (_state?.mainLink?.includes('box')) {
      return getMyBoxOfficeURL(_state.activeTab, _state.orderBy, _state.page)
    }
    return '/my-box-office/table-events'
  }
  const titleLink = state?.mainLink?.includes('back') ? 'Back Office' : 'Box Office'
  const headerReset = () => (
    <Link to={getUrl(state)} className='headerReset' onClick={() => dispatch(resetEventSummary())}>
      <div className='text'>
        <img src={arrow} alt='arrow' className='headerResetArrow' />
        Back to {titleLink}
      </div>
    </Link>
  )
  const goTop = (
    <div className='headerReset goBackBtn'>
      <div className='text' onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
        <img src={arrow} alt='arrow' className='headerResetArrow' />
        Back to top
      </div>
    </div>
  )
  let activeTable = false
  const activeTab = pathname.split('/')[3].replace(/[\-\/]/gi, ' ')
  if (pathname.split('/')[5] === 'rows') {
    activeTable = true
  } else if (pathname.split('/')[5] === 'tables') {
    activeTable = true
  } else if (pathname.split('/')[3] === 'tickets-edit') {
    activeTable = true
  }
  const currentStartDate = currentEventDetails?.startDate || currentEvent?.startDate
  const currentEndDate = currentEventDetails?.endDate || currentEvent?.endDate
  const getUtcOffsetDate = (date, format = 'h:mm') => date ? moment(date).utcOffset(date).format(format) : ''
  const address = currentEventDetails?.address?.split(',').join('').split(' ').join('+') || currentEvent?.address?.split(',').join('').split(' ').join('+')
  const currencySymbol = getCurrencySymbolByCode(currentEventDetails?.currency)
  const objContent = {
    statistics: () => <Statistics />,
    'tickets categories': () => <Categories setTableData={setTableData} tableData={tableData} selectedTicket={selectedTicket} setSelectedTicket={setSelectedTicket} isChange={isChange} updateFunc={setIsChange} currencySymbol={currencySymbol} isResponsive={isResponsive} />,
    'tickets distribution': () => <Distribution currencySymbol={currencySymbol} />,
    'bundles distribution': () => <BundlesDistribution currentEventDetails={currentEventDetails} currencySymbol={currencySymbol} />,
    'random draw': () => <RandomDraw currencySymbol={currencySymbol} />,
    'assigned seating': () => <AssignedSeatings />,
    'assigned table': () => <AssignedTables />,
    'scan tickets': () => <ScanTickets />,
    'purchase details': () => <PurchaseDetails isChange={isChange} updateFunc={setIsChange} eventID={eventID} currencySymbol={currencySymbol} isResponsive={isResponsive} />,
    'financial reporting': () => <FinancialReports eventID={eventID} currencySymbol={currencySymbol} />,
    'tickets edit': () => currentEventDetails._id && isVenue(user?.role) || currentEventDetails._id && currentEventDetails?.isVenueDraft ? <CreateEventV2 currentEventDetails={currentEventDetails} />
      : <MakeTicketComponents currentEventDetails={currentEventDetailsV2} />,
    'payment balances': () => <PaymentBalances eventID={eventID} currencySymbol={currencySymbol} />,
  }
  const navigationPanel = [
    // {
    //   name: 'Statistics',
    //   to: `/my-box-office/event-details/statistics/${eventID}`,
    //   icon: statisticsIcon,
    // },
    {
      name: 'Tickets Categories',
      to: `/my-box-office/event-details/tickets-categories/${eventID}`,
      icon: categoriesIcon,
    },
    {
      name: 'Tickets Distribution',
      to: `/my-box-office/event-details/tickets-distribution/${eventID}/public-sale-tickets`,
      icon: distributionIcon,
    },
    {
      name: 'Bundles',
      to: `/my-box-office/event-details/bundles-distribution/${eventID}`,
      icon: bundlesIcon,
    },
    {
      name: 'Random Draw',
      to: `/my-box-office/event-details/random-draw/${eventID}`,
      icon: randomDrawIcon,
    },
    // {
    //   name: 'Assigned and/or Table Seating',
    //   to: `/my-box-office/event-details/assigned-seating/${eventID}`,
    //   icon: seatsIcon,
    // },
    // {
    //   name: 'Assign Tables',
    //   to: `/my-box-office/event-details/assigned-table/${eventID}`,
    //   icon: tablesIcon,
    // },
    {
      name: 'Financial Reporting',
      to: `/my-box-office/event-details/financial-reporting/${eventID}`,
      icon: financeReport,
    },
    {
      name: 'Online Purchase Details',
      to: `/my-box-office/event-details/purchase-details/${eventID}`,
      icon: purchaseDetails,
    },
  ]
  if (isAdmin(user.role)) {
    const balancesPanel = {
      name: 'Payment Balances',
      to: `/my-box-office/event-details/payment-balances/${eventID}`,
      icon: financeReport,
    }
    navigationPanel.push(balancesPanel)
  }
  if (eventType === 'live' && isOwner) {
    const scannerPanel = {
      name: 'Scan Tickets',
      to: `/my-box-office/event-details/scan-tickets/${eventID}`,
      icon: scannerIcon,
    }
    navigationPanel.splice(4, 0, scannerPanel)
  }
  if (eventType === 'live' && isOwner && currentEventDetails?.seatTemplateID) {
    const assignedSeating = {
      name: 'Assigned and/or Table Seating',
      to: `/my-box-office/event-details/assigned-seating/${eventID}`,
      icon: seatsIcon,
    }
    navigationPanel.splice(5, 0, assignedSeating)
  }
  if (eventType === 'live' && isOwner && currentEventDetails?.seatTemplateID) {
    const assignedTables = {
      name: 'Assign Tables',
      to: `/my-box-office/event-details/assigned-table/${eventID}`,
      icon: tablesIcon,
    }
    navigationPanel.splice(6, 0, assignedTables)
  }
  const navigatePanel = !isOwner ? navigationPanel.filter(item => item.icon !== financeReport) : navigationPanel
  const currentPromoter = isAdmin(user?.role) || isVenue(user?.role) ? currentEventDetails?.userID || currentEvent?.userID : user?._id
  const isVanity = currentEventDetails?.eventUrl || currentEvent?.eventUrl
  const eventLink = isVanity
    ? `${window.location.origin}/buy/${isVanity}`
    : `${window.location.origin}/buy/${currentEventDetails?._id || currentEvent?._id}/${currentPromoter}`

  const vanityLink = (classNames = '') => (
    <a href={eventLink} rel='noopener noreferrer' target='_blank' className={classNames}>{eventLink}</a>
  )

  const linkComponent = <HiddenField label='Event Link' value={vanityLink()} />
  const checkVisibleForAdmin = () => isAdmin(user?.role) && state?.mainLink === '/my-back-office'
  const descriptionItemsByType = {
    virtual: (
      <>
        <div className='eventDescriptionItem'>
          <img src={placeIcon} alt='place icon' />
          Virtual event
        </div>
        <div className='eventDescriptionItem'>
          <img src={zoomIcon} alt='zoom icon' />
          <a
            target='_blank'
            rel='noopener noreferrer'
            href={currentEventDetails?.zoom_url || currentEvent?.zoom_url}
          >
            Zoom link
          </a>
        </div>
      </>
    ),
    live: (
      <>
        <div className='eventDescriptionItem startAligned'>
          <img src={placeIcon} alt='place icon' />
          {currentEventDetails.venue || user?.venueName}
        </div>
        <div className='eventDescriptionItem startAligned'>
          <img src={locationIcon} alt='flagMap' />
          <a
            target='_blank'
            rel='noopener noreferrer'
            href={`https://www.google.com/maps/search/?api=1&query=${address}`}
          >
            {currentEventDetails?.address || currentEvent?.address || user?.address}
          </a>
        </div>
      </>
    ),
    stream: (
      <>
        <div className='eventDescriptionItem'>
          <img src={placeIcon} alt='place icon' />
          {currentEventDetails.venue || user?.venueName}
        </div>
        {currentEventDetails.address && (
          <div className='eventDescriptionItem'>
            <img src={locationIcon} alt='flagMap' />
            <a
              target='_blank'
              rel='noopener noreferrer'
              href={`https://www.google.com/maps/search/?api=1&query=${address}`}
            >
              {currentEventDetails?.address || currentEvent?.address || user?.address}
            </a>
          </div>)}
      </>
    ),
  }
  const streamDetails = ((eventType === 'stream' || (eventType === 'live' && currentEventDetails?.streamKey)) && (
    <>
      <div className='eventDescriptionItem centerAligned'>
        <img src={streamIcon} alt='link icon' />
        <HiddenField label="Event's Private Stream Key" value={currentEventDetails?.streamKey} />
        <CopyToClipboard text={currentEventDetails?.streamKey} onCopy={() => notification.success({ message: 'Stream key copied to clipboard success' })}>
          <img src={copyIcon} alt='copy' className='copyImg' />
        </CopyToClipboard>
      </div>
      <div className='eventDescriptionItem centerAligned'>
        <img src={streamIcon} alt='link icon' />
        <HiddenField label="Event's RTMP Server" value='rtmp://go.eventlive.pro/live' />
        <CopyToClipboard text='rtmp://go.eventlive.pro/live' onCopy={() => notification.success({ message: 'Link copied to clipboard success' })}>
          <img src={copyIcon} alt='copy' className='copyImg' />
        </CopyToClipboard>
      </div>
    </>))
  const bannerStream = ((eventType === 'stream' || (eventType === 'live' && currentEventDetails?.streamKey)) && (<BannerMyBoxOffice />))
  const statistics = { ...currentEventDetailsSummary }
  const modalQRCodeSize = window.innerWidth <= 500 ? 250 : 400

  return (
    <div className='mainLayout'>
      <div id='top' />
      <div className={c(pathname.split('/')[5] === 'tables' ? 'selectedEventTable' : 'selectedEvent', { wrapperTable: activeTable, [camelCase(activeTab)]: !!activeTab })}>
        {!activeTable && (
          <div className='eventInfoWrap'>
            <div className='eventInfo'>
              <div className='headerEventBlockOffice'>
                {headerReset()}
                {(currentEventDetails && !currentEventDetails.isEventBlocked || checkVisibleForAdmin()) && (
                  <div onClick={() => push(`/my-box-office/event-details/tickets-edit/${eventID}`)} className='editButtonWrapper'>
                    <img className='editIcon' src={EditIcon} alt='edit icon' />
                    <span className='editText'>Edit</span>
                  </div>
                )}
              </div>
              <div className='eventNameDetails'>
                <div className='eventDetailsEventName'>{currentEventDetails?.eventName || currentEvent?.eventName}</div>
              </div>
              <div className='imageWrap'>
                {currentEventDetails?.imageUrl?.thumbnail1 || currentEvent?.imageUrl?.thumbnail1
                  ? (
                    <img
                      src={currentEventDetails?.imageUrl?.thumbnail1 || currentEvent?.imageUrl?.thumbnail1}
                      alt='cardDetail'
                      className='eventImage'
                    />)
                  : <div className='eventImageLoading'><Spin spinning={currentEventDetails?.imageUrl?.thumbnail1} /></div>}
              </div>
              <div className='eventDescription'>
                <Button
                  className='btnTypeWhite codeGenButton'
                  onClick={handleModal}
                  disabled={!eventID || isEmpty(currentEventDetails) || currentEventDetails?.isEventBlocked}
                >
                  <div className='customButtonWrapper'>
                    <img src={embedCodeIcon} alt='embed code' />
                    Generate embed code
                  </div>
                </Button>
                <Button
                  className='btnTypeWhite codeGenButton'
                  onClick={handleModalPreview}
                >
                  <div className='customButtonWrapper'>
                    <img src={previewTicketIcon} alt='preview ticket' />
                    Preview Ticket
                  </div>
                </Button>
                {descriptionItemsByType[eventType]}
                <div className='eventDescriptionItem'>
                  <img src={calendarIcon} alt='calendar icon' />
                  {getUtcOffsetDate(currentStartDate, 'MMMM DD, YYYY')}
                </div>
                <div className='eventDescriptionItem'>
                  <img src={timeIcon} alt='time icon' />
                  {getUtcOffsetDate(currentStartDate, 'h:mm A')} - {getUtcOffsetDate(currentEndDate, 'h:mm A')}
                </div>
                <div className='eventDescriptionItem eventLink'>
                  <img src={copyLinkImg} alt='link icon' className='linkIcon' />
                  {linkComponent}
                  <CopyToClipboard text={eventLink} onCopy={() => notification.success({ message: 'Link copied to clipboard success' })}>
                    <img src={copyIcon} alt='copy' className='copyImg' />
                  </CopyToClipboard>
                  <div>
                    <img
                      className='qrCodeImg'
                      onClick={openPopup}
                      src={viewQRCode}
                      alt='QR Code'
                    />
                    {isPopupOpen &&
                      <div className='modal' onClick={handleOverlayClick}>
                        <div className='modal-content'>
                          <img className='closeQrCodeIcon' onClick={closePopup} src={closeIconSvg} alt='Close' />
                          <QRCode
                            removeQrCodeBehindLogo logoPadding={10}
                            size={modalQRCodeSize} logoImage={QRCodeLogoIcon} value={eventLink}
                          />
                        </div>
                      </div>}
                  </div>
                </div>
                {streamDetails}
              </div>
              {bannerStream}
              <div className='eventNavigation'>
                {navigatePanel.map(i => {
                  const activeNavTab = i.to.split('/')[3].replace(/[\-\/]/gi, ' ')
                  return (
                    <Link
                      className={c('navItem', { activeNavItem: activeNavTab.includes(activeTab) })}
                      to={{ pathname: i.to, state }}
                      onClick={handleScroll}
                      key={i.name}
                    >
                      <div className='flex'>
                        <div className='navIcons'>
                          <img src={i.icon} alt='icon' />
                        </div>
                        <div className='text'>{i.name}</div>
                      </div>
                      <img src={arrow} alt='arrow' />
                    </Link>
                  )
                })}
              </div>
            </div>
          </div>
        )}
        {selectedTicket && <ModalDetailTicket tag='PreviewTicket' ticketData={selectedTicket} onCloseModal={() => setSelectedTicket()} />}
        {!activeTable && activeTab !== 'random draw' &&
          <StatisticsBox statistics={statistics} currencySymbol={currencySymbol} />}
        {activeTab !== 'tickets edit' && goTop}
        <div className={pathname.split('/')[5] === 'tables' ? 'eventSelectedPageTable' : 'eventSelectedPage'}>
          <div ref={containerRef} />
          {objContent[activeTab]()}
        </div>
      </div>
      {modalEmbedCode && (
        <ModalEmbedCode
          eventID={modalEmbedCode}
          onClose={() => setModalEmbedCode(null)}
          onCopy={handleCopy}
        />
      )}
    </div>
  )
}

export default EventDetails
