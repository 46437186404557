import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { editProfile } from 'modules/session/session-actions'
import userIcon from 'images/userType.svg'
import PromoterPartnerIcon from 'images/promoterPartner.svg'
import { CustomButton, ModalProfile } from 'components'
import c from 'classnames'
import './index.styl'
import moment from 'moment/moment'

const PersonalInformation = () => {
  const [visible, setVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const { user } = useSelector(state => state.session)
  const dispatch = useDispatch()
  const { email, mobile, firstName, lastName, userName, dateOfBirth } = user
  const onChange = () => setVisible(!visible)
  const onChangeLoading = () => setLoading(!loading)

  const Information = ({ item, index, lastIndex }) => (
    <div className={c('wrapperInformation', { border: index !== lastIndex - 1 })}>
      <div className='left'>{item.title}</div>
      <div className='right'>{item.text}</div>
    </div>
  )
  const userInformations = [
    {
      title: 'Account type',
      text:
  <div className='typeWrapper'>
    {user?.isSubscriptionActive
      ? (
        <div>
          <img src={PromoterPartnerIcon} />
          Promoter Partner
        </div>
      )
      : (
        <div>
          <img src={userIcon} />
          User
        </div>
      )}
  </div>,
    },
    {
      title: 'First Name',
      text: firstName,
    },
    {
      title: 'Last Name',
      text: lastName,
    },
    {
      title: 'User name',
      text: userName,
    },
    {
      title: 'Mobile number',
      text: '+1' + mobile,
    },
    {
      title: 'Email',
      text: email,
    },
    dateOfBirth
      ? {
        title: 'Date of birth',
        text: moment(dateOfBirth).format('MMM D, YYYY'),
      }
      : {},
  ]

  const minorUserInformations = [
    {
      title: 'Account type',
      text:
  <div className='typeWrapper'>
    {user?.isSubscriptionActive
      ? (
        <div>
          <img src={PromoterPartnerIcon} />
          Promoter Partner
        </div>
      )
      : (
        <div>
          <img src={userIcon} />
          User
        </div>
      )}
  </div>,
    },
    {
      title: 'First Name',
      text: firstName,
    },
    {
      title: 'Last Name',
      text: lastName,
    },
    {
      title: 'User name',
      text: userName,
    },
    {
      title: 'Email',
      text: email,
    },
    // push values to field
    {
      title: 'Date of birth',
      text: moment(dateOfBirth).format('MMM D, YYYY'),
    },
    {
      title: 'Mobile number',
      text: '+1' + mobile,
    },
    // {
    //  title: 'Address',
    //  text: null,
    // },
    // {
    //  title: 'School',
    //  text: null,
    // },
    // {
    //  title: 'Parent name',
    //  text: null,
    // }, {
    //  title: 'Parent phone',
    //  text: null,
    // },
    // {
    //  title: 'Parent email',
    //  text: null,
    // },
  ]

  const isLessThan18Years = dateOfBirth => {
    const date = new Date(dateOfBirth)

    const currentDate = new Date()

    let age = currentDate.getFullYear() - date.getFullYear()

    if (
      currentDate.getMonth() < date.getMonth() ||
      (currentDate.getMonth() === date.getMonth() && currentDate.getDate() < date.getDate())
    ) {
      age--
    }

    return age < 18
  }

  // change createdAt to Date of birth
  const informations = isLessThan18Years(user.createdAt) ? userInformations : minorUserInformations

  const onFinish = form => {
    const { firstName, lastName, userName, mobile, birthDate } = form
    if (!lastName || !firstName || !userName || !mobile) return
    onChangeLoading()
    const dateTransform = date => {
      return `${moment(date).format('YYYY-MM')}`
    }
    const obj = {
      firstName,
      lastName,
      mobile,
      userName,
      dateOfBirth: dateTransform(birthDate),

    }
    dispatch(editProfile(obj)).then(() => {
      setLoading(false)
      onChange()
    }).catch(() => setLoading(false))
  }

  return (
    <div className='sectionProfile'>
      <div className='head'>
        <div className='titleSection'>Personal information</div>
        <CustomButton text='Edit' type='white' onClick={onChange} />
      </div>
      <div className='body'>
        {informations.map((i, ind) => <Information item={i} index={ind} key={i.title} lastIndex={informations.length} />)}
      </div>

      <ModalProfile visible={visible} onChange={onChange} onFinish={onFinish} name='information' loading={loading} />
    </div>
  )
}

export default PersonalInformation
