import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
// import { getMyWalletTickets } from 'modules/events/events-actions'
import { getParentApproval, resendParentRequest, selfScanWallet } from 'modules/memberships/memberships-actions'
import { Avatar } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import { CustomButton, MemberLyfeLayout } from '../../components'
// import QrReader from 'react-qr-reader2'
import success from 'images/passSuccess.svg'
import moment from 'moment'

import fail from 'images/passInactive.svg'
import QRScanner from 'react-qr-scanner'
import { ReactComponent as FlashIcon } from 'images/flashIcon.svg'

import './index.styl'
import VisitHistory from './VisitHistory'
import ModalChangeEmail from '../../components/ModalChangeEmail'

const DetailMembership = () => {
  const history = useHistory()
  const { user } = useSelector(state => state?.session)
  const [visible, setVisible] = useState(false)
  const [parentApproveStatus, setParentApproveStatus] = useState('')
  const [showScanner, setShowScanner] = useState(false)
  const [flashEnabled, setFlashEnabled] = useState(false)
  const [scanningStatus, setScanningStatus] = useState(null)
  const [scanCompleted, setScanCompleted] = useState(false)
  const [scannedOn, setScannedOn] = useState([])
  const location = useLocation()
  const dispatch = useDispatch()
  const { ticketData } = location.state
  const isTablet = window.innerWidth < 1024
  const icons = { success, fail }
  const handleVisitHomePage = () => {
    history.push(`/memberPage/${ticketData.eventID}/${ticketData.userID}`)
    window.scrollTo(0, 0)
  }
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  useEffect(() => {
    if (ticketData?.parentApproval) {
      dispatch(getParentApproval(ticketData?.eventID)).then(data => {
        setParentApproveStatus(data?.data?.parentApproval)
      })
    }
  }, [ticketData])

  const resendEmailHandler = () => {
    dispatch(resendParentRequest({ eventID: ticketData?.eventID }))
  }

  const changeEmailHandler = values => {
    const obj = {
      eventID: ticketData?.eventID,
      email: values.email,
    }
    dispatch(resendParentRequest(obj)).then(() => {
      setVisible(false)
    })
  }

  const handleScan = data => {
    if (!data || scanCompleted) return
    setScanCompleted(true)
    const obj = {
      ticketPassBarcode: ticketData?.ticketPasses[0]?.barcode,
      qrCode: data?.text,
    }

    dispatch(selfScanWallet(obj))
      .then(data => {
        setScannedOn(data.data?.scanHistory)
        setStatus('success', data?.data?.scannedOn)
      })
      .catch(error => {
        setStatus('fail', error?.response?.data?.error)
      }).finally(() => {
        setScanCompleted(false)
      })
  }

  const handleError = err => {
    setScanCompleted(false)
    setStatus('fail', err?.response?.data?.error)
  }

  const toggleFlash = () => {
    setFlashEnabled(prev => !prev)
  }
  const allScans = ticketData?.ticketPasses.flatMap(pass => pass.scans) || scannedOn
  const setStatus = (status, message) => {
    setTimeout(() => {
      setScanningStatus({ status, message })
      setShowScanner(false)
    }, 2000)
    setScanCompleted(false)
  }
  const dateScanned = moment(scannedOn[0]?.scannedOn).format('MMM D, YYYY')
  const timeScanned = moment(scannedOn[0]?.scannedOn).format('h:mm A')
  const avatar = user?.image ? <img className='avatarWallet' src={user?.image?.original} alt='' /> : <Avatar size={224} icon={<UserOutlined />} className='defaultAvatarWallet' />
  return (
    <>
      {showScanner && (
        <div className='qrScannerWrapper'>
          <QRScanner
            onError={handleError}
            onScan={handleScan}
            style={{ objectFit: 'cover', width: '100%', height: '100%' }}
            constraints={{
              video: {
                facingMode: 'environment',
                torch: flashEnabled,
              },
            }}
          />
          <div className='qrScannerBtnWrapper'>
            <CustomButton
              text='Cancel'
              className='stopScanning'
              type='dark'
              onClick={() => setShowScanner(false)}
            />
            {isTablet &&
              <CustomButton
                className={`flashToggle ${flashEnabled ? 'flash-on' : 'flash-off'}`}
                text={<FlashIcon className='flash-icon' />}
                type='dark'
                onClick={toggleFlash}
              />}
          </div>
        </div>
      )}
      {scanningStatus !== null && (
        <div className='endScanningWrapper'>
          <div className='response-status-member'>
            <div className='response-container-member'>
              <img src={icons[scanningStatus?.status]} alt='icon' className='status-icon' />
              {scanningStatus?.status === 'success' ? (
                <div className='statusResultTitle'>Success</div>
              ) : (
                <div className='statusResultTitle'>Pass Inactive</div>
              )}
              {scanningStatus?.status === 'success' && (
                <div className='eventName'>{ticketData?.eventName}</div>
              )}
              <div className='dateScanned'>{dateScanned}</div>
              <div className='timeScanned'>{timeScanned}</div>
              {scanningStatus?.message && (<span className='response-message'>{scanningStatus?.message}</span>)}
            </div>
            <CustomButton
              text='Ok'
              className='okBtn'
              type='dark'
              onClick={() => setScanningStatus(null)}
            />
          </div>
        </div>
      )}
      <MemberLyfeLayout className='detailMembershipLayoutWrapper'>

        {parentApproveStatus !== 'APPROVED' && ticketData?.parentApproval && (
          <div className='inactiveBlock'>
            <div className='inactiveWrapper'>
              <div className='titleInactive'>Inactive Account</div>
              <div className='messageWrapper'>
                <div className='inactiveMessage'>We have sent an email with instructions to your parents at</div>
                <div className='emailMessage'>{user?.email}</div>
                <div className='inactiveMessage'>Please check your inbox</div>
              </div>
              <div className='btnInactiveWrapper'>
                <CustomButton
                  text='Change Email'
                  className='changeEmail'
                  onClick={() => setVisible(true)}
                  type='dark'
                />
                <CustomButton
                  text='Resend Email'
                  className='resendEmail'
                  onClick={resendEmailHandler}
                  type='dark'
                />
              </div>
            </div>
          </div>
        )}
        <div className='detailVisitWrapper'>
          <div className='detailMembershipWrapper'>
            <div className='headerDetailWrapper'>
              {avatar}
              <div className='namesWrapper'>
                <div className='eventName'>{ticketData?.eventName}</div>
                <div className='ticketName'>{ticketData?.ticketName}</div>
              </div>
            </div>
            <div className='bottomDetailWrapper'>
              <div className='detailsWrapper'>
                <div className='details'>
                  <div className='name'>{user?.firstName + ' ' + user?.lastName}</div>
                  <div className='userName'>{user?.userName}</div>
                </div>
                <div className='btnMemberWrpapper'>
                  <CustomButton
                    text='Visit Home Page'
                    className='visitHomePage'
                    type='dark'
                    onClick={handleVisitHomePage}
                  />
                  {parentApproveStatus !== 'APPROVED' && ticketData?.parentApproval ? null
                    : (
                      <CustomButton
                        text='Scan QR Code'
                        className='scanQRCode'
                        type='dark'
                        onClick={() => setShowScanner(true)}
                      />
                    )}
                </div>
              </div>
            </div>
          </div>
          <VisitHistory scans={allScans} />
        </div>
        <ModalChangeEmail submitForm={changeEmailHandler} visible={visible} setVisible={setVisible} />
      </MemberLyfeLayout>
    </>
  )
}
export default DetailMembership
