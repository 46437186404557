import {
  Home,
  Auth,
  MyWallet,
  MyBoxOffice,
  MakeTicket,
  DetailTicket,
  MyProfile,
  ContactUs,
  Help,
  TicketGuide,
  Stream,
  StreamV2,
  Scanner,
  Widget,
  MyBackOffice,
  UsersBackOffice,
  SendMail,
  NoFound,
  Order,
  MockTicket,
  VenueHomePage,
  MyTemplates,
  CreateNewTemplate,
  Template,
  NewHomePage,
  ContactPromoter,
  MakePage,
  MemberLyfeHome,
  MemberPage,
  MemberOffice,
  DetailPage,
  DetailMembership,
  ParentApproval,
} from 'pages'
export const authorized = [
  {
    path: '/my-wallet',
    component: MyWallet,
    redirectTo: '/auth/sign-in?redirectTo=/my-wallet',
  },
  {
    path: '/my-member-wallet',
    component: MyWallet,
    redirectTo: '/auth/sign-in?redirectTo=/my-member-wallet',
  },
  {
    path: '/detail-membership/:eventID',
    component: DetailMembership,
  },
  {
    path: '/ticket-guide',
    component: TicketGuide,
  },
  {
    path: '/my-box-office/table-events',
    component: MyBoxOffice,
    redirectTo: '/auth/sign-in?redirectTo=/my-box-office/table-events?activeTab=ACTIVE&orderBy=DATE_DESC&page',
  },
  {
    path: '/my-box-office/table-events?:activeTab',
    component: MyBoxOffice,
    redirectTo: '/auth/sign-in?redirectTo=/my-box-office/table-events?activeTab=SUBPROMOTIONS&orderBy=DATE_DESC&page=1',
  },
  {
    path: '/my-box-office/event-details/statistics/:eventID',
    component: MyBoxOffice,
  },
  {
    path: '/my-box-office/event-details/tickets-edit/:eventID',
    component: MyBoxOffice,
  },
  {
    path: '/my-box-office/event-details/tickets-categories/:eventID',
    component: MyBoxOffice,
  },
  {
    path: '/my-box-office/event-details/random-draw/:eventID',
    component: MyBoxOffice,
  },
  {
    path: '/my-box-office/event-details/bundles-distribution/:eventID',
    component: MyBoxOffice,
  },
  {
    path: '/my-box-office/event-details/tickets-distribution/:eventID/gift-tickets',
    component: MyBoxOffice,
  },
  {
    path: '/my-box-office/event-details/tickets-distribution/:eventID/sub-promote-tickets',
    component: MyBoxOffice,
  },
  {
    path: '/my-box-office/event-details/tickets-distribution/:eventID/public-sale-tickets',
    component: MyBoxOffice,
  },
  {
    path: '/my-box-office/event-details/tickets-distribution/:eventID/all-tickets',
    component: MyBoxOffice,
  },
  {
    path: '/my-box-office/event-details/tickets-distribution/:eventID/direct-sale-tickets',
    component: MyBoxOffice,
  },
  {
    path: '/my-box-office/event-details/tickets-distribution/:eventID/cash-sale-tickets',
    component: MyBoxOffice,
  },
  {
    path: '/my-box-office/event-details/assigned-seating/:eventID',
    component: MyBoxOffice,
  },
  {
    path: '/my-box-office/event-details/assigned-table/:eventID',
    component: MyBoxOffice,
  },
  {
    path:
      '/my-box-office/event-details/assigned-table/:eventID/tables/:sectionID',
    component: MyBoxOffice,
  },
  {
    path:
      '/my-box-office/event-details/assigned-table/:eventID/rows/:sectionID',
    component: MyBoxOffice,
  },

  // {
  //   path: '/my-box-office/event-details/assigned-seating/special-seatings',
  //   component: MyBoxOffice,
  // },
  {
    path: '/my-box-office/event-details/scan-tickets/:eventID',
    component: MyBoxOffice,
  },
  {
    path: '/my-box-office/event-details/purchase-details/:eventID',
    component: MyBoxOffice,
  },
  {
    path: '/my-box-office/event-details/financial-reporting/:eventID',
    component: MyBoxOffice,
  },
  {
    path: '/my-box-office/event-details/payment-balances/:eventID',
    component: MyBoxOffice,
  },
  {
    path: '/my-box-office/event-details/review-event-details/:eventID',
    component: MyBoxOffice,
  },
  {
    path: '/member-office/table-members',
    component: MemberOffice,
  },
  {
    path: '/member-office/table-members',
    component: MemberOffice,
    redirectTo: '/auth/sign-in?redirectTo=/my-box-office/table-members?&orderBy=DATE_DESC&page',
  },
  {
    path: '/member-office/table-members?:activeTab',
    component: MemberOffice,
  },
  {
    path: '/member-office/member-details/statistics/:eventID',
    component: MemberOffice,
  },
  {
    path: '/member-office/member-details/member-edit/:eventID',
    component: MemberOffice,
  },
  {
    path: '/member-office/member-details/member-categories/:eventID',
    component: MemberOffice,
  },
  {
    path: '/member-office/member-details/member-distribution/:eventID/gift-membership',
    component: MemberOffice,
  },
  {
    path: '/member-office/member-details/member-distribution/:eventID/public-sale-membership',
    component: MemberOffice,
  },
  {
    path: '/member-office/member-details/member-distribution/:eventID/all-memberships',
    component: MemberOffice,
  },
  {
    path: '/member-office/member-details/member-distribution/:eventID/direct-sale-membership',
    component: MemberOffice,
  },
  {
    path: '/member-office/member-details/member-distribution/:eventID/cash-sale-membership',
    component: MemberOffice,
  },
  {
    path: '/member-office/member-details/scan-history/:eventID',
    component: MemberOffice,
  },
  {
    path: '/member-office/member-details/purchase-details/:eventID',
    component: MemberOffice,
  },
  {
    path: '/member-office/member-details/financial-reporting/:eventID',
    component: MemberOffice,
  },
  {
    path: '/member-office/member-details/payment-balances/:eventID',
    component: MemberOffice,
  },
  {
    path: '/member-office/member-details/review-event-details/:eventID',
    component: MemberOffice,
  },
  {
    path: '/member-office/member-details/membership-roster/:eventID',
    component: MemberOffice,
  },
  {
    path: '/make-my-tickets',
    component: MakeTicket,
  },
  {
    path: '/make-my-memberLyfe',
    component: MakePage,
  },
  {
    path: '/scanner',
    component: Scanner,
  },
  {
    path: '/scanner/:eventID',
    component: Scanner,
  },
  {
    path: '/my-back-office',
    component: MyBackOffice,
  },
  {
    path: '/user-listing',
    component: UsersBackOffice,
  },
  {
    path: '/my-templates',
    component: MyTemplates,
  },
  {
    path: '/my-templates/:id',
    component: Template,
  },
  {
    path: '/my-templates',
    component: MyTemplates,
    redirectTo: '/auth/sign-in?redirectTo=/my-templates/table-templates?page=1',
  },
  {
    path: '/memberlyfe-home',
    component: MemberLyfeHome,
  },
  {
    path: '/memberPage/:customURL',
    component: MemberPage,
  },
  {
    path: '/memberPage/:id/:userId',
    component: MemberPage,
  },
  {
    path: '/create-new-template',
    component: CreateNewTemplate,
  },
  {
    path: '/support',
    component: Home,
  },
  {
    path: '/auth/stripe-auth',
    component: Auth,
  },
]

export const unauthorized = [
  {
    path: '/auth/sign-in',
    component: Auth,
    redirectTo: '/auth/sign-in',
  },
  {
    path: '/auth/choose-account',
    component: Auth,
  },
  {
    path: '/auth/sign-up',
    component: Auth,
  },
  {
    path: '/auth/venue-sign-up',
    component: Auth,
  },
  {
    path: '/auth/reset-password',
    component: Auth,
  },
  {
    path: '/auth/confirmation-password',
    component: Auth,
  },
  {
    path: '/auth/confirmation-email/:lang/:token',
    component: Auth,
  },
  {
    path: '/auth/confirmation-reset-password/:lang/:token',
    component: Auth,
  },
  {
    path: '/my-wallet/guest/:inviteID',
    component: MyWallet,
  },
  {
    path: '/memberlyfe-home',
    component: MemberLyfeHome,
  },
  {
    path: '/memberPage/:customURL',
    component: MemberPage,
  },
  {
    path: '/memberPage/:id/:userId',
    component: MemberPage,
  },
]

export const global = [
  {
    path: '/my-profile',
    component: MyProfile,
  },
  {
    path: '/parentApprove/:token',
    component: ParentApproval,
  },
  {
    path: '/contact-us',
    component: ContactUs,
  },
  {
    path: '/send-mail',
    component: SendMail,
  },
  {
    path: '/help',
    component: Help,
  },
  {
    path: '/help/faq',
    component: Help,
  },
  {
    path: '/help/terms-and-conditions',
    component: Help,
  },
  {
    path: '/help/overview/:page',
    component: Help,
  },
  {
    path: '/watch',
    component: Stream,
  },
  {
    path: '/streamV2',
    component: StreamV2,
  },
  {
    path: '/widget',
    component: Widget,
  },
  {
    path: '/order/:id',
    component: Order,
  },
  {
    path: '/404/:reason',
    component: NoFound,
  },
  {
    path: '/404',
    component: NoFound,
  },
  {
    path: '/412/contact-promoter',
    component: ContactPromoter,
  },
  {
    path: '/buy/:customURL',
    component: DetailTicket,
  },
  {
    path: '/buy/:id/:userId',
    component: DetailTicket,
  },
  {
    path: '/buyPage/:customURL',
    component: DetailPage,
  },
  {
    path: '/buyPage/:id/:userId',
    component: DetailPage,
  },
  {
    path: '/home',
    component: Home,
  },
  {
    path: '/mock-ticket',
    component: MockTicket,
  },
  {
    path: '/venue/:venueUrl',
    component: VenueHomePage,
  },
  {
    path: '/',
    component: NewHomePage,
  },
  {
    path: '*',
    component: NoFound,
  },
]

export const unauthorizedPathNames = unauthorized.map(route => route.path)
export const authorizedPathNames = authorized.map(route => route.path)
